import type { Router } from 'vue-router'

import type { FeaturedArticles } from '@backmarket/http-api/src/api-specs-acquisition/buying-guide/featured-article'
import type { ArticleCard } from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCard.types'

import { buildGetArticleHrefFn } from '../../helpers/buildGetArticleHrefFn'

export const mapFeaturedArticles = (
  featuredArticles: FeaturedArticles,
  router: Router,
  currentUrl: URL,
): ArticleCard[] => {
  const getLink = buildGetArticleHrefFn({ router, currentUrl })

  return featuredArticles.map<ArticleCard>((art) => ({
    category: art.translatedCategory,
    id: art.pk.toString(),
    imageAlt: art.bannerAlt,
    imageSrc: art.banner,
    link: getLink(art.link),
    text: art.text,
    title: art.title,
  }))
}
